<template>
  <div class="b-table-sticky-header custom-scroll h-250">
    <table class="table table-striped">
      <thead>
        <tr>
          <th v-for="(head, hIndex) in getTableHead" :key="hIndex">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, trIndex) in getTableBody" :key="trIndex">
          <td v-for="(td, tdIndex) in tr" :key="tdIndex">
            {{ td }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'PreviewTable',
  computed: {
    getTableHead () {
      return this.tableSampleData.head
    },
    getTableBody () {
      return this.tableSampleData.body
        .filter((body) => body.filter((b) => b).length)
        .filter((f) => f.length)
    },
    ...mapState({
      tableSampleData: (state) => state.widgets.tableSampleData
    })
  }
}
</script>
